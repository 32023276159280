import React from "react";
import { CenterBox } from "../components/CenterBox";
import { Header } from "../components/Header";

export const Home = () => {
  return (
    <div className="home">
      <Header />
      <main className="text-center">
        <div>
          <div
            className="logo-cont mt-3 mt-md-0"
            style={{ position: "relative" }}
          >
            <img src="/assets/logo.png" className="logo" alt="Line" />
          </div>
          <CenterBox />
        </div>
      </main>
    </div>
  );
};
