import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { BlockchainContext } from "../context/BlockchainContext";

export const CenterBox = () => {
  const [mintamount, setMint] = useState(1);

  const [mintCost, setMintCost] = useState();
  const {
    phase1mint,
    phase2mint,
    max,
    cost,
    totalSupply,

    phase,

    phase3mint,
  } = useContext(BlockchainContext);
  const [feedback, setFeedback] = useState(" The mint has ended");
  const mintHandler = async () => {
    /*
    if (phase === 0) {
      setFeedback("SALE HAS NOT STARTED YET");
    } else if (phase === 1) {
      if (mintamount > 1) {
        console.log(mintamount);
        setFeedback("Only allowed to mint 1");
      } else {
        const tx = await phase1mint();
        if (tx === 1) {
          setFeedback("Minting Successful");
        } else {
          if (tx === "Invalid merkle proof") {
            setFeedback("You are not allowed to mint");
          } else {
            setFeedback(tx);
          }
        }
      }
    } else if (phase === 2) {
      const tx = await phase2mint(mintamount);
      if (tx === 1) {
        setFeedback("Minting Successful");
      } else {
        setFeedback(tx);
      }
    } else if (phase === 3) {
      const tx = await phase3mint(mintamount);
      if (tx === 1) {
        setFeedback("Minting Successful");
      } else {
        setFeedback(tx);
      }
    }
    */
  };

  useEffect(() => {
    if (phase === 1) {
      setMintCost(0);
    } else {
      setMintCost(cost * mintamount);
    }
  });
  return (
    <div>
      <div className="center-box">
        <img src="/assets/logo.gif" className="skull" alt="Skull" />
        <div className="mint">
          <div>Mint</div>
          <div>{totalSupply}/3456</div>
        </div>
        <div className="columns">
          <div>
            <div className="amount d-flex align-items-center justify-content-between gap-0 gap-sm-5 py-2">
              <div>Amount</div>
              <div className="add d-flex align-items-center gap-2">
                <div>
                  <a
                    onClick={() =>
                      setMint(mintamount > 1 ? mintamount - 1 : mintamount)
                    }
                  >
                    -
                  </a>
                </div>
                <div>{mintamount}</div>
                <div>
                  <a
                    onClick={() =>
                      setMint(mintamount <= 14 ? mintamount + 1 : mintamount)
                    }
                  >
                    +
                  </a>
                </div>
              </div>
            </div>
            <div className="vertical"></div>
            <div className="right-part p-2">
              <div className="d-flex align-items-center gap-2">
                <div className="">or</div>
                <div className="btn-container">
                  <button className="btn " onClick={() => setMint(2)}>
                    2
                  </button>
                  <button className="btn " onClick={() => setMint(4)}>
                    4
                  </button>
                  <button
                    className="btn"
                    onClick={() => setMint(parseFloat(max))}
                  >
                    Max
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="total d-flex justify-content-between">
            <div>Total</div>
            <div>
              <img
                src="/assets/menu.png"
                className="menu"
                alt="Menu"
                width="23"
              />
              {parseFloat(mintCost).toFixed(4)} ETH
            </div>
          </div>{" "}
        </div>
        {feedback}
        <div className="mint-btn">
          <button className="btn" onClick={mintHandler}>
            <img src="/assets/mint-btn.png" alt="Menu" />
            <span>mint</span>
          </button>
        </div>
      </div>
    </div>
  );
};
