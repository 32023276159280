import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import ApeContractData from "../blockchain/ApeContract";
import ApeContractData1 from "../blockchain/ApeContract1";

import WalletConnectProvider from "@walletconnect/web3-provider";

export const BlockchainContext = createContext();

const { ethereum } = window;

const getProvider = () => {
  console.log("ethereum", ethereum);
  return new ethers.providers.Web3Provider(ethereum);
};

const getSigner = () => {
  const provider = getProvider();
  return provider.getSigner();
};

// returns promise
const getSignerAddress = () => {
  const provider = getProvider();
  return provider.getSigner().getAddress();
};

const getCurrentNetwork = () => {
  const provider = getProvider();
  return provider.getNetwork();
};

// returns Promise
const getNetworkChainId = async () => {
  const network = await getCurrentNetwork();
  return network.chainId;
};

export const BlockchainContextProvider = (props) => {
  const [currentSigner, setCurrentSigner] = useState("");
  const [currentSignerAddress, setCurrentSignerAddress] = useState("");

  const [phase, setPhase] = useState();

  let [cost, setCost] = useState();

  const [totalSupply, setTotalSupply] = useState();
  const [provider, setProvider] = useState();
  let [max, setMax] = useState();

  useEffect(() => {
    // checkIfWalletIsConnected();
    listenMMAccount(); // Event is registered in background
  });

  async function listenMMAccount() {
    ethereum.on("accountsChanged", async function () {
      window.location.reload();
    });

    ethereum.on("chainChanged", (currentChainId) => {
      window.location.reload();
    });
  }

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        //   Wallet Connect
        // Setup WalletConnect Providers
        const provider = new WalletConnectProvider({
          rpc: {
            //    1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
            //  11155111:
            //   "https://eth-sepolia.g.alchemy.com/v2/FeKbwUcb3jknnkUEy60nG-iIyF5U4Yja",
            8453: "https://base-mainnet.g.alchemy.com/v2/Yr9x1HMmiMiMyRnglLWlVtzrwYap-hbi",
          },
        });

        await provider.enable();

        //  Create Web3 instance
        const web3Provider = new ethers.providers.Web3Provider(provider);
        setProvider(web3Provider);

        // Check Network
        const chainId = await (await web3Provider.getNetwork()).chainId;

        //  Enable session (triggers QR Code modal)

        //  if (chainId !== 1 || chainId !== 11155111 || chainId !== 8453) {
        //    alert("Please Change Network to Base !");
        //    return;
        //  }
        if (chainId !== 8453) {
          alert("Please Change Network to Base !");
          return;
        }

        // Set Current Signer

        setCurrentSigner(web3Provider.getSigner());

        // Set Current Signer Address
        const signerAddress = await web3Provider.getSigner().getAddress();
        setCurrentSignerAddress(signerAddress);

        await getCost(web3Provider);
        await getTotalSupply(web3Provider);
        await getSalePhase(web3Provider);
      } else {
        // Request Metamask for accounts
        await ethereum.request({ method: "eth_requestAccounts" });

        // Check Network
        const chainId = await getNetworkChainId();

        //  Enable session (triggers QR Code modal)

        //  if (chainId !== 1 || chainId !== 11155111 || chainId !== 8453) {
        //    alert("Please Change Network to Base !");
        //    return;
        //  }
        if (chainId !== 8453) {
          alert("Please Change Network to Base !");
          return;
        }

        // SetProvider
        const provider = new ethers.providers.Web3Provider(ethereum);
        setProvider(provider);

        // Set Current Signer
        const signer = getSigner();
        setCurrentSigner(signer);

        // Set Current Signer Address
        const signerAddress = await getSignerAddress();
        setCurrentSignerAddress(signerAddress);

        await getCost(provider);
        await getTotalSupply(provider);
        await getSalePhase(provider);
      }
    } catch (error) {
      alert(error);

      throw new Error("No Ethereum Object");
    }
  };

  // Get APE Contract

  const getApeContract = async () => {
    console.log(currentSigner);
    if (!currentSigner) {
      console.log("Wallet not connected");
      // setIsLoading(false);
      return;
    }

    const ApeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );
    return ApeContract;
  };

  /* 
  * -------------------------------------------
            Functions
  * -------------------------------------------
  */

  const phase1mint = async () => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      let res = await fetch(
        "https://jzpw1s2ixf.execute-api.eu-central-1.amazonaws.com/OG/" +
          currentSignerAddress
      );
      let proof = await res.json();
      const options1 = {
        value: 0,
      };
      let tx = await apeContract
        .connect(currentSigner)
        .whitelistFreeMint(proof, options1);
      await tx.wait();
      let _totalSupply = await apeContract.totalSupply();
      setTotalSupply(_totalSupply.toString());
      return 1;
    } catch (error) {
      return show_error_alert(error);
    }
  };

  const phase2mint = async (mintAmount) => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      console.log("mint Amount:", mintAmount);

      let value = cost * mintAmount;
      value = parseFloat(value).toFixed(4);
      console.log("balance:", value);
      const options = {
        value: ethers.utils.parseEther(value.toString()),
      };

      let tx = await apeContract
        .connect(currentSigner)
        .phase2mint(mintAmount, options);
      await tx.wait();
      let _totalSupply = await apeContract.totalSupply();
      setTotalSupply(_totalSupply.toString());
      return 1;
    } catch (error) {
      return show_error_alert(error);
    }
  };

  const phase3mint = async (mintAmount) => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      console.log("mint Amount:", mintAmount);

      let value = cost * mintAmount;
      value = parseFloat(value).toFixed(4);
      console.log("balance:", value);
      const options = {
        value: ethers.utils.parseEther(value.toString()),
      };

      let tx = await apeContract
        .connect(currentSigner)
        .phase3mint(mintAmount, options);
      await tx.wait();
      let _totalSupply = await apeContract.totalSupply();
      setTotalSupply(_totalSupply.toString());
      return 1;
    } catch (error) {
      return show_error_alert(error);
    }
  };

  async function show_error_alert(error) {
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "You have not staked any NFTs",
      "Sent Amount Wrong",
      "No balance to claim",
      "No NFTs selected to stake",
      "No NFTs selected to unstake",
      "Max Supply Reached",
      "You have already Claimed Free Nft.",
      "Presale have not started yet.",
      "You are not in Presale List",
      "Staking Period is still not over",
      "Presale Ended.",
      "You are not Whitelisted.",
      "Sent Amount Not Enough",
      "Exceeds max tx per address",
      "Max 20 Allowed.",
      "insufficient funds",
      "Sale is Paused.",
      "mint at least one token",
      "Exceeds max tx per address",
      "Not enough tokens left",
      "incorrect ether amount",
      "The contract is paused!",
      "5 tokens per wallet allowed in presale",
      "10 tokens per wallet allowed in publicsale",
      "Invalid merkle proof",
      "Not enough tokens allowed in current phase",
      "Sold out!!!",
      "No more tokens left in current phase",
      "Wallet limit Reached",
      "Exceeds max NFT per wallet",
      "Exceeds max NFT allowed per Wallet",
      "You are not whitelisted",
      "Exceeds max Nfts per wallet.",
      "Exceeds max NFT allowed per Wallet",
      "Insufficient funds!",
      "Whitelist minting is Paused!",
      "Only 1022 NFTs are available for Presale",
      "Whitelist supply has ended.",
      "Mint has not started yet",
      "Insufficient funds!",
      "You are not whitelisted",
      "Exceeds max limit per wallet",
      "Exceeds max supply.",
      "Mint has not started yet",
      "Exceeds max limit per wallet",
      "Invalid merkle proof",
      "Phase 1 Mint has not started",
      "Free Mint Already Claimed",
    ];

    for (let i = 0; i < error_list.length; i++) {
      if (temp_error.includes(error_list[i])) {
        // set ("Transcation Failed")
        // alert(error_list[i]);
        console.log(error_list[i]);
        return error_list[i];
      }
    }
  }

  const getTotalSupply = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      // Set Stake

      let _totalSupply = await apeContract.totalSupply();

      setTotalSupply(_totalSupply.toString());
    } catch (error) {
      console.log(error);
    }
  };

  const getSalePhase = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );
    const apeContract1 = new ethers.Contract(
      ApeContractData1.address,
      ApeContractData1.abi,
      provider
    );

    try {
      let sale1 = await apeContract.phase1paused();
      let sale2 = await apeContract.phase2paused();
      let sale3 = await apeContract.phase3paused();

      const signerAddress = await provider.getSigner().getAddress();
      console.log("max ", signerAddress);

      let juice = await apeContract1.balanceOf(signerAddress, 2);
      juice = parseFloat(juice);
      let blue = await apeContract1.balanceOf(signerAddress, 3);
      blue = parseFloat(blue);
      let red = await apeContract1.balanceOf(signerAddress, 4);
      red = parseFloat(red);
      console.log("max ", juice);
      console.log("phase ", blue);
      console.log("phase ", red);

      if (sale1 === false) {
        setPhase(1); //1
        setMax(1);
      } else if (sale2 === false) {
        setPhase(2); // 2 for presale
        if (juice > 0 && blue > 0 && red > 0) {
          setMax(8);
        } else if ((juice > 0 || blue > 0) && red > 0) {
          setMax(6);
        } else if (juice === 0 && blue === 0 && red > 0) {
          setMax(4);
        } else {
          setMax(2);
        }
      } else if (sale3 === false) {
        setPhase(3);
        if (juice > 0 && blue > 0 && red > 0) {
          setMax(14);
        } else if ((juice > 0 || red > 0) && blue > 0) {
          setMax(10);
        } else if (juice === 0 && red === 0 && blue > 0) {
          setMax(6);
        } else if (juice === 0 && blue === 0 && red === 0) {
          setMax(3);
        } else {
          setMax(4);
        }
      } else {
        setPhase(0);
        setMax(1); // sale not started yet
      }
      console.log("max1 ", max);
      console.log("phase1 ", phase);
    } catch (error) {
      console.log(error);
    }
  };

  const getCost = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let phase = await apeContract.phase1paused();
      let _cost;
      if (phase === false) {
        _cost = 0;
      } else {
        phase = await apeContract.phase2paused();
        if (phase === false) {
          _cost = await apeContract.phase2cost();
          _cost = ethers.utils.formatEther(_cost);
        } else {
          _cost = await apeContract.phase3cost();
          _cost = ethers.utils.formatEther(_cost);
        }
      }
      setCost(_cost);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BlockchainContext.Provider
      value={{
        currentSigner,
        currentSignerAddress,
        connectWallet,

        cost,
        max,

        totalSupply,

        phase,

        phase1mint,
        phase2mint,
        phase3mint,
      }}
    >
      {props.children}
    </BlockchainContext.Provider>
  );
};
