import React from 'react'
import { Home } from './page/Home';

const App = () => {
  return (
    <div className='App'  style={{ background: 'url(' + '/assets/noise.gif' + ')' }}>
      <Home />
    </div>
  )
}

export default App;
