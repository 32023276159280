import React from "react";

import { useContext, useState, useEffect } from "react";
import { BlockchainContext } from "../context/BlockchainContext";
export const Header = () => {
  const { connectWallet, currentSignerAddress } = useContext(BlockchainContext);
  const handleConnectWallet = async () => {
    await connectWallet();
  };
  return (
    <header>
      <div className="d-flex justify-content-between mx-4 mx-md-5">
        <div className="left">
          <div className="d-flex">
            <div>
              <a href="https://opensea.io/collection/0xfruggerz">OPENSEA</a>
              <br />
              <img src="/assets/lineUnderOpenSea.svg" alt="Line" />
            </div>
            <div className="mt-4 d-none d-sm-block">
              <a>FRUGGERZ</a>
              <br />
              <img src="/assets/funcks.png" alt="Line" />
            </div>
          </div>
          <div className="mt-0">
            <a href="https://www.x.com/0xfruggerz" target="_blank">
              TWITTER
            </a>
            <br />
            <img src="/assets/lineUnderOpenSea.svg" alt="Line" />
          </div>
        </div>
        <div>
          <div
            className="right text-center"
            style={{ backgroundImage: "url(" + "/assets/connect.png" + ")" }}
          >
            <button className="btn" onClick={handleConnectWallet}>
              {!currentSignerAddress ? (
                <a> Connect Wallet</a>
              ) : (
                <a>Wallet Connected</a>
              )}
            </button>
            <br />
          </div>
        </div>
      </div>
    </header>
  );
};
